<template>
    <div class="d-flex align-center">
        <div>
            <strong>{{ currentSetCount }} / {{ setsCount }}</strong>
        </div>
        <v-chip color="primary" class="ml-2 v-chip-light-bg primary--text">
            <strong>{{ scores }}</strong>&nbsp;
            <span>{{ $transChoice('scores', scores % 20) }}</span>
        </v-chip>
    </div>
</template>

<script>
export default {
    name: 'QuestionsProcessTitle',
    props: ['currentSetCount', 'setsCount', 'scores']
}
</script>

<style lang=scss>

</style>
