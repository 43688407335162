<template>
    <v-card flat class="questions-process questions-process--step">
        <v-card-title>
            <questions-process-title
                :current-set-count="currentSetCount"
                :sets-count="setsCount"
                :scores="item.score"
            />
            <v-spacer/>
            <questions-process-timer
                v-if="timer"
                :timer="timer"
            />
        </v-card-title>
        <v-progress-linear
            :value="progress"
        />
        <v-card-text
            class="px-0"
            v-bind="vCardTextBindings"
        >
            <v-card-title class="justify-center">
                {{ item.title }}
            </v-card-title>
            <v-card-subtitle
                class="text-center"
                v-html="item.description"
            />
            <v-sheet
                v-if="media.length > 0"
                color="transparent"
                max-width="552"
                class="mx-auto mb-6"
            >
                <media-view
                    v-for="(document, i) in media"
                    :key="document.uuid"
                    :item="document"
                    class="text-center"
                    :class="i > 0 ? 'mt-2' : ''"
                />
            </v-sheet>
            <div class="px-6 mb-6">
                <questions-type-input
                    :key="item.uuid"
                    :value="item.options"
                    :type="item.type"
                    :score="item.score"
                    @process="onProcess"
                />
            </div>
        </v-card-text>
        <v-divider/>
        <v-card-actions v-if="!complete">
            <v-spacer/>
            <v-btn
                v-if="showSkip"
                :disabled="last"
                text
                color="primary"
                @click="onSkip"
            >
                {{ $trans('Skip') }}
            </v-btn>
            <v-btn
                :disabled="!result"
                depressed
                color="success"
                @click="onReply"
            >
                {{ $trans('Reply') }}
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import MediaView from '@apps/media/components/MediaView'
import QuestionsProcessTitle from '@apps/questions/components/QuestionsProcess/QuestionsProcessTitle'
import QuestionsProcessTimer from '@apps/questions/components/QuestionsProcess/QuestionsProcessTimer'
import questionsProcessBaseMixin from '@apps/questions/components/QuestionsProcess/mixins/questionsProcessBaseMixin'
export default {
    name: 'QuestionsProcessStep',
    mixins: [questionsProcessBaseMixin],
    components: {
        QuestionsProcessTimer,
        QuestionsProcessTitle,
        MediaView,
        QuestionsTypeInput: () => import('@/apps/questions/components/QuestionsType/QuestionsTypeInput')
    },
    props: {
        item: Object,
        skippable: {
            type: Boolean,
            default: true
        },
        last: {
            type: Boolean,
            default: false
        },
        complete: {
            type: Boolean,
            default: false
        },
        progress: {
            type: Number,
            default: 0
        },
        setsCount: {
            type: Number,
            default: 0
        },
        currentSetCount: {
            type: Number,
            default: 1
        },
        timer: {
            type: Object,
            default: null
        },
    },
    computed: {
        showSkip() {
            return this.skippable
        },
        media() {
            return this.item.media.map(o => o.document)
        }
    },
    data: () => ({
        result: null
    }),
    methods: {
        _makeResult(data) {
            return {
                uuid: this.item.uuid,
                title: this.item.title,
                description: this.item.description,
                type: this.item.type,
                ...data
            }
        },
        onProcess(data) {
            if(!data) {
                this.result = null

                return
            }

            this.result = this._makeResult(data)
        },
        onSkip() {
            this.$emit('skip', this._makeResult({}))
        },
        onReply() {
            this.$emit('reply', this.result)
        }
    }
}
</script>
