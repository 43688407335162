<template>
    <v-chip
        v-if="timer"
        v-bind="timerBindings"
    >
        {{ timer.timeDisplay }}
    </v-chip>
</template>

<script>
export default {
    name: 'QuestionsProcessTimer',
    props: ['timer'],
    computed: {
        timerBindings() {
            const bindings = {
                label: true,
                color: 'success',
                class: ['v-chip-light-bg']
            }

            let color = 'success'

            switch (this.timer.quarter) {
                case 3:
                    color = 'warning'
                    break
                case 4:
                    color = 'error'
                    break
                default:
                    color = 'success'
                    break
            }

            if(this.timer.current === this.timer.time) {
                color = 'primary'
            }

            bindings.color = color
            bindings.class.push(`${color}--text`)

            return bindings
        }
    }
}
</script>

<style lang=scss>

</style>
